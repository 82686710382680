import Box from "@mui/material/Box";
import { useState } from "react";
import { AdminsNavIcon, AirtimeIcon, CouponBagNavIcon, DashboardIcon, General3Icon, LightModeIcon, LogoutIcon, NavContainer, NavLink, SettingsIcon, useAuthContext } from "suregifts-component-lib";
import PreferenceModal from "../auth/preference/PreferenceModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TemplateNavIcon } from "../../icons/TemplateNavIcon";
const links = [
  {
    to: "/",
    icon: DashboardIcon,
    title: "Dashboard",
  },
  {
    to: "/orders",
    icon: General3Icon,
    title: "Orders",
  },
  {
    to: "/businesses",
    icon: General3Icon,
    title: "Business Unit",
  },
  {
    to: "/email-templates",
    icon: TemplateNavIcon,
    title: "Email Template",
  },
  {
    to: "/coupons",
    icon: CouponBagNavIcon,
    title: "Coupons",
  },
  {
    to: "/payments",
    icon: General3Icon,
    title: "Wallet Top-up",
  },
  {
    to: "/api-credentials",
    icon: General3Icon,
    title: "Api Credentials",
  },

  {
    to: "/transactions",
    icon: General3Icon,
    title: "Transactions",
  },
  {
    to: "/utilities",
    icon: AirtimeIcon,
    title: "Bills & Airtime",
  },
  {
    to: "/admins",
    icon: AdminsNavIcon,
    title: "Admins",
  },
];

function NavBar() {
  const [showPreference, setShowPreference] = useState(false);
  const { user, signOut } = useAuthContext();
  const navigate = useNavigate();
  const logout = () => {
    signOut();
    navigate("/login");
  };
  return (
    <>
      <Box sx={{ listStyle: "none", padding: 0, margin: 0 }} component="ul">
        {links.map((link) => (
          <li key={link.title}>
            <NavLink {...link} />
          </li>
        ))}
        <li
          style={{
            flexGrow: 1,
            minHeight: "100px",
          }}
        ></li>
        {[
          {
            to: "/settings",
            icon: SettingsIcon,
            title: "Settings",
            onClick: (e) => {
              e.preventDefault();
              setShowPreference(true);
            },
          },

          {
            to: "/logout",
            icon: LogoutIcon,
            title: "Logout",
            onClick: (e) => {
              e.preventDefault();
              logout();
            },
          },
        ].map((link) => (
          <li key={link.title}>
            <NavLink {...link} />
          </li>
        ))}
      </Box>
      {showPreference && <PreferenceModal open={showPreference} handleClose={() => setShowPreference(false)} />}
    </>
  );
}

export default NavBar;
